export const swRegister = () => {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.register("./swFunctions.mjs", {
      type: 'module',
    }).then(
      (registration) => {
        console.log("Service worker registration successful:", registration);
      },
      (error) => {
        console.error(`Service worker registration failed: ${error}`);
      },
    );
  }
}
