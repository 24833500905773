import {
  Box,
  Checkbox,
  HStack,
  Input,
  VStack,
  Text,
  useToast,
  useColorModeValue,
  Divider,
  Textarea,
  Flex,
} from '@chakra-ui/react'
import { useLiveQuery } from 'dexie-react-hooks'
import { CheckIcon, DeleteIcon } from '@chakra-ui/icons'
import React, { useEffect, useState } from 'react'
import nid from 'nid'
import { collapseText, log } from '../common/utils'
import { addUser, deleteUser, getUser, updateUser } from './user.model'
import { syncWithRemote } from '../common/syncWithRemote'
import { palette, paletteDark } from '../config'
import { ButtonConfirm, ButtonConfirmLegal } from '../common/UtilComponents'
import { db, User as UserType } from '../store/db'
import './User.css'
import { Emergency } from './Emergency'
import { useSwipeable } from 'react-swipeable'
import { HelpIcon } from '../common/Helpicon'

type Props = {
  leaderAddsNewWalker?: boolean
  onClick?: (id: string) => void
}

export const User = (props: Props) => {
  const toast = useToast()
  const appStatusDexie = useLiveQuery(() => db.appStatus.toCollection().last())
  const usersDexie = useLiveQuery(() => db.users.toArray())
  const placeholderColor = useColorModeValue(
    palette.titleSec,
    paletteDark.title
  )
  const [activeUsers, setActiveUsers] = useState<UserType[]>([])
  const [currentUser, setCurrentUser] = useState<Partial<UserType>>()
  const [displayFormFields, setDisplayFormFields] = useState(
    props.leaderAddsNewWalker
  )
  const [disableOk, setDisableOk] = useState(false)
  const [isVisitor, setIsVisitor] = useState(false)

  // Handle swipe to help page
  const swipeHandlers = useSwipeable({
    onSwiped: (eventData) => {
      if (eventData.dir === 'Right') {
        db.appStatus.update('Tripsheets', {
          navTo: 'help',
          parameters: 'user',
        })
      }
    },
  })

  // Effect create lists of types of users
  useEffect(() => {
    if (props.leaderAddsNewWalker) return
    if (!usersDexie) return
    if (!appStatusDexie) return

    const users =
      usersDexie?.filter((u) => u.status !== 'deleted' && !u.isBlocked) || []
    users.sort((a, b) => a.fullName.localeCompare(b.fullName))
    setActiveUsers(users)
  }, [usersDexie, appStatusDexie])

  // Effect to set the screen for a new or existing user
  useEffect(() => {
    if (!appStatusDexie) return
    if (props.leaderAddsNewWalker) return // In that case, dont need this

    if (!appStatusDexie.userId) {
      // This is a new user
      setIsVisitor(appStatusDexie.parameters === 'joinAsVisitor')
      startEditUser()
      return
    }

    // Get the existing user. Sometimes usersDexie comes in late, in that case, do nothing
    const user = usersDexie?.find((u) => u?.userId === appStatusDexie.userId)
    if (!user) {
      setCurrentUser({})
      return
    }

    // We have a user, so go to edit mode
    setIsVisitor(user.isVisitor)
    setCurrentUser(user)
    startEditUser()
  }, [appStatusDexie, props.leaderAddsNewWalker])

  const startEditUser = () => {
    setDisplayFormFields(true)
  }

  const doEditUser = async (type?: 'delete' | 'ok') => {
    if (type === 'delete') {
      await deleteUser(currentUser?.userId)
      // Must sync while we are still an app user - but this can take a while so only remove our id on start up
      toast({
        title: `Please wait 10+ seconds`, // For the sync to complete
        duration: 12_000,
        isClosable: true,
      })
      await syncWithRemote()
      setTimeout(() => {
        location.reload()
      }, 12_000)
    } else if (type === 'ok' && currentUser?.fullName?.trim()) {
      // Save this user. Do we know this user?
      const userExists = await getUser(currentUser?.userId)
      if (userExists) {
        await updateUser(currentUser as UserType)
      } else {
        await addUser(currentUser as UserType)
        log(`Adding new walker: ${currentUser.fullName})`, true)
      }

      if (props.leaderAddsNewWalker) {
        if (props.onClick) props.onClick(currentUser?.userId || '')
      } else {
        db.appStatus.update('Tripsheets', {
          userId: currentUser.userId,
          navTo: 'walks',
        })
      }

      // Sync user changes
      await syncWithRemote()
    }
  }

  const handleInput = async (event: any) => {
    if (!appStatusDexie) return
    const field = event.target.id
    const value =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value

    let _user = currentUser
    if (!_user?.userId) {
      // Create new user with id and set as default
      const userId = nid() // Generate an id
      if (!userId) return // This can't/shouldn't happen, just keeping ts happy
      _user = {
        userId,
        firstName: '',
        lastName: '',
        fullName: '',
        phone: '',
        emergencyPhone: '',
        emergencyName: '',
        emergencyPhone1: '',
        emergencyName1: '',
        contactBy: 'club',
        address: '',
        holdsPlbId: '',
        isSuperAdmin: false,
        isVisitor: isVisitor,
        isPrivateDetails: false,
        isBlocked: false,
        isAdmin: false,
        receiveUpdates: false,
        medicalForm: false,
        medicalFormLocation: '',
        password: '',
        status: 'active',
      }
    }

    // Update the relevant field
    const updatedUser = {
      ..._user,
      [field]: value,
    }

    if (field === 'isVisitor') setIsVisitor(value)

    // Must have a sensible name
    updatedUser.fullName = `${updatedUser.firstName || ''} ${
      updatedUser.lastName || ''
    }`
    if (updatedUser.fullName.trim().length < 4) {
      setDisableOk(true)
      setCurrentUser(updatedUser)
      return
    }

    // Check if this name already exists (and it's not me)
    if (updatedUser.fullName !== currentUser?.fullName) {
      const already = activeUsers.find(
        (u) => collapseText(u.fullName) === collapseText(updatedUser.fullName)
      )
      if (already) {
        setDisableOk(true)
        toast({
          title: `The name ${updatedUser.fullName} is already taken`,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
        return
      }
    }

    // Must have a phone number
    if (!updatedUser.phone || updatedUser.phone.trim().length < 8) {
      setDisableOk(true)
      setCurrentUser(updatedUser)
      return
    }

    if (!collapseText(updatedUser.fullName)) {
      setDisableOk(true)
      setCurrentUser(updatedUser)
      return
    }

    setDisableOk(false)
    setCurrentUser(updatedUser)
  }

  return (
    <Box {...swipeHandlers}>
      <Box className={'userContainer'}>
        {!currentUser?.userId && !props.leaderAddsNewWalker && (
          <>
            <Box mt={4}>Please tell us who you are.</Box>
            <Box>You only need to do this once.</Box>
          </>
        )}

        {!currentUser?.userId && !displayFormFields && (
          <>
            <Box mt={8}>
              If this section remains empty, please reload the app.
            </Box>
          </>
        )}

        {/* Help icon */}
        {Boolean(currentUser?.userId) && <HelpIcon from={'user'} />}

        {/* Details form */}
        <Box
          className={'userForm'}
          display={displayFormFields ? 'flex' : 'none'}
          mt={4}
        >
          <VStack>
            <Box alignSelf={'start'}>
              <Text>Registered as a {isVisitor ? 'visitor' : 'member'}</Text>
            </Box>
            <Box alignSelf={'start'}>
              <Text as={'i'} fontSize={'xs'}>
                Note: You can only register 1 person per device or browser.
                {isVisitor && (
                  <Text as={'i'} fontSize={'xs'}>
                    {' '}
                    So each person should register themself on a personal
                    device, computer or browser
                  </Text>
                )}
              </Text>
            </Box>

            <Box alignSelf={'start'}>
              <Text as={'b'}>
                {props.leaderAddsNewWalker ? 'Their details:' : 'Your details:'}
              </Text>{' '}
              <Text as={'i'} fontSize={'xs'}>
                (Required)
              </Text>
            </Box>
            {props.leaderAddsNewWalker && (
              <Box>
                <HStack spacing={4} w={'100%'}>
                  <Checkbox
                    id={'isVisitor'}
                    isChecked={currentUser?.isVisitor}
                    isInvalid={!currentUser?.isVisitor}
                    onChange={handleInput}
                  >
                    <Text fontSize={'md'} color={palette.actionSec}>
                      Is a visitor (so not a member)
                    </Text>
                  </Checkbox>
                </HStack>
                <Divider mt={4} mb={4} />
                <Text as={'b'}>
                  The new walker must click on the buttons "Ok" and "I Agree"
                </Text>
              </Box>
            )}
            <HStack spacing={4} w={'100%'}>
              <Input
                id={'firstName'}
                defaultValue={currentUser?.firstName}
                placeholder="First name"
                _placeholder={{ opacity: 0.6, color: placeholderColor }}
                onChange={handleInput}
              />
              <Input
                id={'lastName'}
                defaultValue={currentUser?.lastName}
                placeholder="Last name"
                _placeholder={{ opacity: 0.6, color: placeholderColor }}
                onChange={handleInput}
              />
            </HStack>
            <VStack spacing={4} w={'100%'}>
              <Input
                id={'phone'}
                defaultValue={currentUser?.phone}
                onChange={handleInput}
                placeholder={
                  props.leaderAddsNewWalker ? 'Ph number' : 'Your number'
                }
                _placeholder={{ opacity: 0.6, color: placeholderColor }}
              />
              {isVisitor && (
                <Textarea
                  id={'address'}
                  defaultValue={currentUser?.address || ''}
                  onChange={handleInput}
                  placeholder="Address"
                  _placeholder={{ opacity: 0.6, color: placeholderColor }}
                />
              )}
            </VStack>
            {!props.leaderAddsNewWalker && currentUser && (
              <Emergency
                currentUser={currentUser}
                onChange={handleInput}
                joinAsVisitor={isVisitor}
              />
            )}
            {!props.leaderAddsNewWalker && (
              <>
                {currentUser?.isAdmin && (
                  <HStack spacing={4} w={'100%'} h={8}>
                    <Checkbox
                      id={'canSee'}
                      isChecked={currentUser?.isAdmin}
                      onChange={handleInput}
                    >
                      <Text
                        fontSize={'md'}
                        color={palette.actionSec}
                        mt={6}
                        mb={6}
                      >
                        Is admin (can see other walker's details & manage PLBs)
                      </Text>
                    </Checkbox>
                  </HStack>
                )}
                {currentUser?.isSuperAdmin && (
                  <HStack spacing={4} w={'100%'} h={8}>
                    <Checkbox
                      id={'isAdmin'}
                      isChecked={currentUser?.isSuperAdmin}
                      onChange={handleInput}
                    >
                      <Text
                        fontSize={'md'}
                        color={palette.actionSec}
                        mt={6}
                        mb={6}
                      >
                        Is a super admin
                      </Text>
                    </Checkbox>
                  </HStack>
                )}
              </>
            )}
            {/* Various buttons */}
            <HStack spacing={4} justify={'end'} w={'100%'}>
              {appStatusDexie?.userId && !props.leaderAddsNewWalker && (
                <ButtonConfirm
                  icon={<DeleteIcon />}
                  okAction={() => doEditUser('delete')}
                  text={'from registrations'}
                  btnText={'Remove me'}
                />
              )}
              <ButtonConfirmLegal
                icon={<CheckIcon />}
                okAction={() => doEditUser('ok')}
                text={'Add me to the app'}
                for={isVisitor ? 'visitor' : 'member'}
                color={palette.action}
                btnText={'Ok'}
                isDisabled={disableOk}
              />
            </HStack>
          </VStack>
        </Box>
      </Box>
    </Box>
  )
}
